<template>
    <div class="image-input" :class="{ 'full-width': fullWidth }">
        <template v-if="!processing && image">
            <entry-tile
                :entry="entry"
                :competition="competition"
                :create="create"
                view="picker"
                @click="launchFilePicker()"
            />
        </template>

        <template v-else>
            <entry-tile :competition="competition" :create="create" view="empty" @click="launchFilePicker()" />
        </template>

        <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="processing">
            <v-progress-circular :value="progress" :width="3" color="dark"></v-progress-circular>
        </v-overlay>

        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input
            type="file"
            ref="file"
            @change="fileSelected($event.target.files)"
            style="display: none"
            accept="image/*"
        />

        <!-- error dialog displays any potential error messages -->
        <v-dialog v-model="errorDialog" content-class="confirm" width="300">
            <v-card class="alert-dialog no-selection dark--text">
                <div class="pa-3">
                    <p class="text-center mb-0"><b>Error</b></p>
                </div>

                <v-card-text class="pa-3 mb-0 text-center" v-html="errorText"></v-card-text>

                <v-card-actions class="actions pa-3">
                    <v-btn color="primary" uppercase @click="errorDialog = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// Components
import EntryTile from "@/components/global/entry-tile.vue";

// Packages
import _ from "lodash";

export default {
    name: "image-upload",

    props: {
        // Use "value" to enable using v-model
        value: [String, Object],
        competition: Object,
        small: Boolean,
        readonly: Boolean,
        fullWidth: Boolean,
        create: Boolean,
        sizeRestriction: Boolean,
        allowPNG: Boolean,
    },

    components: {
        EntryTile,
    },

    data() {
        return {
            errorDialog: null,
            errorText: "",
            uploadFieldName: "file",
            maxSize: 1024,
            processing: false,
            progress: 0,
            bytesLoaded: 0,
            bytesTotal: 0,
        };
    },

    computed: {
        imageUrl() {
            if (this.image) {
                return this.$fluro.asset.imageUrl(this.image);
            }
            return "";
        },
        image() {
            let value = this.value;

            if (value && value._id) return value._id;

            return value;
        },
        entry() {
            return {
                data: {
                    image: this.image,
                },
            };
        },
    },

    methods: {
        reset() {
            this.processing = false;
            this.progress = 0;
            this.bytesLoaded = 0;
            this.bytesTotal = 0;
        },
        launchFilePicker() {
            if (!this.processing && !this.readonly) this.$refs.file.click();
        },
        fileSelected(files) {
            var self = this;

            var file = _.first(files);
            // Clear the input's value to allow selecting the same file again
            this.$refs.file.value = "";
            console.log("file selected!", file);

            var type = file.type.split("/")[1];

            const pngFilesAllowed = this.allowPNG || false;
            if (pngFilesAllowed) {
                if (type !== "jpg" && type !== "jpeg" && type !== "png") {
                    self.errorDialog = true;
                    self.errorText = "Image must be a JPEG";
                    return;
                }
            } else {
                if (type !== "jpg" && type !== "jpeg" && type !== "png") {
                    self.errorDialog = true;
                    self.errorText = "Image must be a JPEG";
                    return;
                }
            }

            ///////////////////////////////////////

            var _URL = window.URL || window.webkitURL;

            var img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            var restricted = this.sizeRestriction;
            img.onload = function () {
                console.log("Size restriction", restricted);
                if (!restricted) {
                    self.processing = true;
                    self.uploadFile(file);
                } else if (
                    (this.width >= this.height && this.width < 3000) ||
                    (this.height >= this.width && this.height < 3000)
                ) {
                    self.errorDialog = true;
                    self.errorText = "Image must be at least 3000px on the longest side";
                } else if (
                    (this.width >= this.height && this.width > 5000) ||
                    (this.height >= this.width && this.height > 5000)
                ) {
                    self.errorDialog = true;
                    self.errorText = "Image must be smaller than 5000px on the longest side";
                } else {
                    self.processing = true;
                    self.uploadFile(file);
                }

                _URL.revokeObjectURL(objectUrl);
            };

            img.src = objectUrl;
        },
        uploadFile(file) {
            var self = this;

            ///////////////////////////////////////

            var formData = new FormData();

            let realm;
            let live;
            // let sandbox;
            //Now have a sandbox org so this isnt needed
            if (this.create) {
                live = this.$app.liveRealm;
                // sandbox = this.$app.sandboxRealm;
            } else {
                live = self.competition.realms.some((realm) => realm == self.$app.liveRealm);
                // sandbox = self.competition.realms.some((realm) => realm == self.$app.sandboxRealm);
            }

            realm = self.$app.liveRealm;
            // if (sandbox) realm = self.$app.sandboxRealm;

            console.log("REALM", realm);

            formData.append(
                "json",
                JSON.stringify({
                    _type: "image",
                    realms: [realm],
                })
            );

            formData.append("file", file, file.name);

            ///////////////////////////////////////

            var config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    // 'Authorization': 'Bearer ' + window.applicationData._application.apikey
                },
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    // do whatever you like with the percentage complete
                    // maybe dispatch an action that will update a progress bar or something
                    self.progress = percentCompleted;
                    self.bytesLoaded = progressEvent.loaded;
                    self.bytesTotal = progressEvent.total;

                    ///////////////////////////////////////////////////

                    self.$forceUpdate();
                },
            };

            ///////////////////////////////////////

            return self.$fluro.api
                .post(`/file/upload`, formData, config)
                .then(function ({ data }) {
                    console.log(data);
                    self.$emit("input", data);

                    //TRIGGER SAVE ENTRY AFTER SUCCESSFUL UPLOAD
                    self.$emit("success");
                    self.reset();
                })
                .catch(function (err) {
                    self.reset();
                });
        },
    },
};
</script>
<style lang="scss">
.image-input {
    position: relative;
    width: 100%;

    &.full-width {
        .entry {
            padding-top: 56.25% !important;
        }
    }

    .clear-btn {
        position: absolute;
        bottom: -28px;
        width: 100%;
        left: 0;
        z-index: 6;
    }
}
</style>
