import FluroContent from "./fluro-content";

class Feedback extends FluroContent {
    constructor() {
        super("feedback");
    }
}

const _Feedback = new Feedback();
export default _Feedback;
