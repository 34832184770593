<template>
    <v-dialog v-model="display" persistent content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card class="judge-dialog no-selection">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center justify-center">
                            <v-textarea class="field" placeholder="Write your reason here..." hide-details dense filled solo flat v-model="model.data.reason"></v-textarea>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions class="actions light pa-3 sticky bottom">
                <v-btn :color="cancelColor" uppercase @click="cancel">
                    <span class="dark--text">{{ cancelLabel }}</span>
                </v-btn>
                <v-btn :disabled="!valid" :color="actionColor" uppercase @click="submit">{{ actionLabel }}</v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
// Services
import Abstain from "@/modules/app-module/abstain";

// Packages
import _ from "lodash";

export default {
    name: "abstain-dialog",

    props: {
        title: {
            type: String,
            default() {
                return "Abstain from Judging";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            item: null,
            model: {
                data: {
                    reason: "",
                },
            },
        };
    },

    computed: {
        valid() {
            let model = this.model;
            let valid = false;

            if (!model?.data?.reason) return valid;

            valid = true;

            return valid;
        },
    },

    methods: {
        init(item, model) {
            this.showError = false;
            this.loading = false;

            if (!item) {
                this.item = null;
            } else {
                let clonedModel = _.cloneDeep(item);
                this.item = clonedModel;
            }

            if (!model) {
                this.model = {
                    data: {
                        reason: "",
                    },
                };
            } else {
                let clonedModel = _.cloneDeep(model);
                this.model = clonedModel;
            }
        },
        open(item, model, message) {
            this.init(item, model);
            this.message = message;
            this.display = true;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        confirm() {
            this.loading = true;
            this.$emit("confirm", this.model);
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
            this.model = {
                data: {
                    reason: "",
                },
            };
            this.item = null;
        },
        async submit() {
            let model = this.model;
            let item = this.item;

            let competition = item?.data?.competition;

            this.loading = true;

            let user = this.$app.user;
            let sandbox = competition.realms.some((realm) => realm?._id == this.$app.sandboxRealm || realm == this.$app.sandboxRealm);

            let payload = {
                title: `Abstaining from Judging ${this.item.title}`,
                realms: [sandbox ? this.$app.sandboxRealm : this.$app.liveRealm],
                data: model.data,
                parent: this.item,
                managedOwners: [user.persona],
            };

            try {
                let result;

                if (this.model._id) {
                    result = await Abstain.update(this.model._id, payload).then(({ data }) => data);
                } else {
                    result = await Abstain.create(payload).then(({ data }) => data);
                }

                console.log("RESULT", result);

                this.$emit("confirm", result);
            } catch (err) {
                console.log("ERROR", err);
                this.showError = true;
                this.errorMessage = err;
                this.loading = false;
            }

            // this.loading = false;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.judge-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }

    .rating-field {
        width: 100%;

        .v-input__control {
            min-height: initial;
            height: auto !important;

            .v-input__slot {
                padding: 12px !important;

                input {
                    font-size: 50px;
                    max-height: initial;
                    font-weight: 700;
                }
            }
        }

        &.error--text {
            input {
                color: var(--v-error-base) !important;
            }
        }
    }

    .out-of {
        width: 100%;
        font-size: 50px;

        b {
            font-weight: 700 !important;
        }
    }
}
</style>
