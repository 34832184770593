<template>
    <div class="submission">
        <!-- Print CSS - hidden by default -->

        <print-css :entry="entry" :contact="contact"></print-css>

        <template v-if="readOnly">
            <v-container style="max-width: 768px" v-if="!judge">
                <v-row>
                    <v-col cols="12">
                        <h3 class="mb-6">Name</h3>
                        <p class="mb-0 caption">{{ entry.title }}</p>
                    </v-col>

                    <v-col cols="12" v-if="published && feedback.length">
                        <h3 class="mb-6">Feedback</h3>

                        <template v-if="feedback.length > 1">
                            <v-expansion-panels v-model="panel" accordion>
                                <v-expansion-panel class="ma-0" v-for="(item, i) in feedback" :key="i">
                                    <v-expansion-panel-header>
                                        <b>Feedback {{ i + 1 }}</b>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div v-if="item.data && item.data.feedback" v-html="item.data.feedback"></div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>

                        <template v-else-if="singleFeedback">
                            <div
                                v-if="singleFeedback.data && singleFeedback.data.feedback"
                                v-html="singleFeedback.data.feedback"
                            ></div>
                        </template>
                    </v-col>

                    <v-col cols="12">
                        <h3>Entry</h3>
                    </v-col>
                </v-row>
            </v-container>

            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="select-container">
                            <v-select
                                v-if="competitionMedia == 'video'"
                                v-model="selectedVideo"
                                :items="videos"
                                align-center
                                justify-center
                                class="custom-select"
                                item-text="label"
                            ></v-select>
                        </div>
                        <div class="asset-outer">
                            <div class="p-absolute fit">
                                <template v-if="competitionMedia == 'video'">
                                    <iframe
                                        class="light full-width full-height cursor-pointer"
                                        v-if="videoSource"
                                        width="100%"
                                        height="315"
                                        :src="videoSource"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </template>
                                <template v-else-if="image">
                                    <v-img
                                        contain
                                        class="light full-width full-height cursor-pointer"
                                        :src="$fluro.asset.imageUrl(image)"
                                        @click="enlarge(image)"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height grey ma-0" align="center" justify="center">
                                                <v-progress-circular
                                                    :width="2"
                                                    :size="20"
                                                    color="dark"
                                                    indeterminate
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </template>

                                <template v-else>
                                    <v-row class="fill-height full-width grey ma-0" align="center" justify="center">
                                        <v-icon color="dark-grey" x-large>mdi-image-outline</v-icon>
                                    </v-row>
                                </template>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="mb-6" style="max-width: 768px">
                <v-row>
                    <v-col cols="12">
                        <h3 class="mb-6">Category</h3>

                        <div v-html="category"></div>
                    </v-col>

                    <v-col cols="12" v-if="body && body.length">
                        <h3 class="mb-6">Description</h3>

                        <div v-html="body"></div>
                    </v-col>

                    <v-col cols="12" v-if="cameraUsed">
                        <h3 class="mb-6">Camera Used</h3>

                        <div v-html="cameraUsed"></div>
                    </v-col>

                    <template v-if="isPrint">
                        <v-col cols="12" v-if="printerUsed">
                            <h3 class="mb-6">Printer Used</h3>

                            <div v-html="printerUsed"></div>
                        </v-col>

                        <v-col cols="12" v-if="paperUsed">
                            <h3 class="mb-6">Paper Used</h3>

                            <div v-html="paperUsed"></div>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </template>

        <template v-else>
            <v-container class="mb-6" style="max-width: 768px">
                <v-row>
                    <!-- <v-col cols="12">
                        <v-text-field v-model="model.title" :value="model.title" solo flat hide-details class="transparent-field large--text full-width" prepend-icon="mdi-pencil-outline" placeholder="Please enter a name for this entry..."> </v-text-field>
                    </v-col> -->

                    <v-col cols="12">
                        <h3 class="mb-6">Competition</h3>

                        <div class="group-item pa-6">
                            <div class="d-flex align-center">
                                <template v-if="competitionImage">
                                    <v-img
                                        class="rounded-lg mr-6"
                                        width="100"
                                        height="70"
                                        :src="$fluro.asset.imageUrl(competitionImage)"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height grey ma-0" align="center" justify="center">
                                                <v-progress-circular
                                                    :width="2"
                                                    :size="20"
                                                    color="dark"
                                                    indeterminate
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </template>

                                <div>
                                    <p class="small--text mb-0">
                                        <b>{{ competitionTitle }}</b>
                                    </p>
                                    <span class="small--text primary--text">{{ competitionDates }}</span>
                                </div>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="12">
                        <template v-if="competitionMedia == 'video'">
                            <h3 class="mb-6">Video</h3>

                            <div class="group-item pa-6">
                                <v-row>
                                    <v-col cols="12">
                                        <p class="primary--text small--text">Short Video (~ 2 minutes)</p>
                                        <p class="small--text">
                                            Please upload your video to Youtube and paste the link in below.
                                        </p>
                                        <v-text-field
                                            v-model="videoSourceShort"
                                            type="test"
                                            class="field"
                                            dense
                                            filled
                                            solo
                                            flat
                                            label="Video source"
                                            placeholder="https://www.youtube.com/watch?v=example"
                                            required
                                            :rules="[rules.required]"
                                            hint="Enter the video link. Such as https://www.youtube.com/watch?v=example"
                                            persistent-hint
                                        ></v-text-field>

                                        <iframe
                                            v-if="model.data.videoSourceShort"
                                            width="100%"
                                            height="315"
                                            :src="model.data.videoSourceShort"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        ></iframe>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="primary--text small--text">Full Video</p>
                                        <p class="small--text">
                                            Upload the full version here. If your full version is the same as the short
                                            version please also enter that here.
                                        </p>
                                        <v-text-field
                                            v-model="videoSourceFull"
                                            type="test"
                                            class="field"
                                            dense
                                            filled
                                            solo
                                            flat
                                            label="Video source"
                                            placeholder="https://www.youtube.com/watch?v=example"
                                            required
                                            :rules="[rules.required]"
                                            hint="Enter the video link. Such as https://www.youtube.com/watch?v=example"
                                            persistent-hint
                                        ></v-text-field>

                                        <iframe
                                            v-if="model.data.videoSourceFull"
                                            width="100%"
                                            height="315"
                                            :src="model.data.videoSourceFull"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        ></iframe>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="6" md="8">
        <p class="primary--text small--text">Additional Images</p>

        <draggable class="row full-width" :list="additional" v-bind="dragOptions">
            <v-col cols="6" md="3" v-for="(image, index) in additional" :key="index">
                <image-upload :readonly="competitionHasNotStarted || competitionClosed" small :competition="competition" v-model="model.data.additionalImages[index]"></image-upload>
            </v-col>
        </draggable>
    </v-col> -->
                                </v-row>
                            </div>
                        </template>
                        <template v-else>
                            <h3 class="mb-6">Image</h3>

                            <div class="group-item pa-6">
                                <v-row>
                                    <v-col cols="12">
                                        <p class="primary--text small--text">Entry</p>
                                        <p class="small--text">
                                            JPEGs only accepted. RAW files must be available upon request. 3000px on the
                                            longest side (max 5000px). sRGB Profile. Save as maximum quality JPEG.
                                            Capture date must be included in the metadata.
                                        </p>
                                        <image-upload
                                            :readonly="$status.competitionClosed(competition)"
                                            full-width
                                            :sizeRestriction="true"
                                            :competition="competition"
                                            v-model="model.data.image"
                                            @success="$emit('updateEntry')"
                                        ></image-upload>
                                    </v-col>

                                    <!-- <v-col cols="12" sm="6" md="8">
                                    <p class="primary--text small--text">Additional Images</p>

                                    <draggable class="row full-width" :list="additional" v-bind="dragOptions">
                                        <v-col cols="6" md="3" v-for="(image, index) in additional" :key="index">
                                            <image-upload :readonly="competitionHasNotStarted || competitionClosed" small :competition="competition" v-model="model.data.additionalImages[index]"></image-upload>
                                        </v-col>
                                    </draggable>
                                </v-col> -->
                                </v-row>
                            </div>
                        </template>
                    </v-col>

                    <v-col cols="12">
                        <h3 class="mb-6">Category</h3>

                        <div class="group-item pa-6">
                            <v-row>
                                <v-col cols="12">
                                    <p class="primary--text small--text">Select a category</p>

                                    <v-chip-group
                                        v-model="model.data.category"
                                        @change="checkCategory"
                                        active-class="primary--text"
                                        column
                                    >
                                        <v-chip
                                            v-for="category in categories"
                                            :value="category._id"
                                            :key="category._id"
                                            >{{ category.title }}</v-chip
                                        >
                                    </v-chip-group>
                                </v-col>

                                <v-col cols="12" v-if="selectedCategory && selectedCategory._id">
                                    <div class="small--text" v-html="selectedCategory.body"></div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                    <v-col cols="12" v-if="requireDescription">
                        <h3 class="mb-6">Description</h3>

                        <div class="group-item pa-6">
                            <v-row>
                                <v-col cols="12">
                                    <p class="primary--text small--text">
                                        Please write a brief description about this entry (optional)
                                    </p>

                                    <div class="text-field">
                                        <v-textarea
                                            class="field"
                                            placeholder="Write your description here..."
                                            hide-details
                                            dense
                                            filled
                                            solo
                                            flat
                                            v-model="model.data.body"
                                        ></v-textarea>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                    <v-col cols="12" v-if="cameraUsedQuestion">
                        <h3 class="mb-6">Image Creation Specifics</h3>

                        <div class="group-item pa-6">
                            <v-row>
                                <v-col cols="12">
                                    <p class="primary--text small--text">Camera used</p>

                                    <v-chip-group v-model="model.data.cameraUsed" active-class="primary--text" column>
                                        <v-chip v-for="(camera, index) in cameras" :value="camera" :key="index">{{
                                            camera
                                        }}</v-chip>
                                    </v-chip-group>

                                    <v-text-field
                                        class="small--text"
                                        label="What camera did you use?"
                                        required
                                        :rules="[rules.required]"
                                        single-line
                                        v-if="model.data.cameraUsed == 'Other'"
                                        v-model="model.data.cameraOther"
                                        :value="model.data.cameraOther"
                                        hide-details
                                    ></v-text-field>
                                </v-col>

                                <template v-if="isPrint">
                                    <v-col cols="12">
                                        <p class="primary--text small--text">Printer used</p>

                                        <v-chip-group
                                            v-model="model.data.printerUsed"
                                            active-class="primary--text"
                                            column
                                        >
                                            <v-chip
                                                v-for="(printer, index) in printers"
                                                :value="printer"
                                                :key="index"
                                                >{{ printer }}</v-chip
                                            >
                                        </v-chip-group>

                                        <v-text-field
                                            class="small--text"
                                            label="What printer did you use?"
                                            required
                                            :rules="[rules.required]"
                                            single-line
                                            v-if="model.data.printerUsed == 'Other'"
                                            v-model="model.data.printerOther"
                                            :value="model.data.printerOther"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <p class="primary--text small--text">Paper used</p>

                                        <v-chip-group
                                            v-model="model.data.paperUsed"
                                            active-class="primary--text"
                                            column
                                        >
                                            <v-chip v-for="(paper, index) in papers" :value="paper" :key="index">{{
                                                paper
                                            }}</v-chip>
                                        </v-chip-group>

                                        <v-text-field
                                            class="small--text"
                                            label="What paper did you use?"
                                            required
                                            :rules="[rules.required]"
                                            single-line
                                            v-if="model.data.paperUsed == 'Other'"
                                            v-model="model.data.paperOther"
                                            :value="model.data.paperOther"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                    </v-col>

                    <v-col cols="12">
                        <h3 class="mb-6">Questions</h3>

                        <div class="group-item pa-6">
                            <v-row>
                                <v-col cols="12" v-for="(question, index) in competition.data.questions" :key="index">
                                    <template v-if="question.category.some((p) => p == selectedCategory?._id)">
                                        <p class="primary--text small--text">{{ question.question }}</p>
                                        <v-textarea
                                            :prepend-icon="question.required ? 'mdi-exclamation-thick' : null"
                                            rows="1"
                                            auto-grow
                                            :counter="question.limit"
                                            v-model="model.data.questions[index]"
                                            :rules="[rules.length(question.limit)]"
                                        ></v-textarea>
                                    </template>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <image-dialog ref="imageDialog"></image-dialog>
        <alert-dialog ref="alertDialog" hide-action width="300"></alert-dialog>
    </div>
</template>

<script>
// Components
import PrintCss from "@/components/layout/print-css.vue";
import ImageUpload from "@/components/global/image-upload.vue";
import ImageDialog from "@/components/dialogs/image-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";

// Packages
import _ from "lodash";
import moment from "moment";
import Draggable from "vuedraggable-axis";

export default {
    name: "submission",

    props: {
        value: Object,
        entry: Object,
        entries: Array,
        categories: Array,
        readOnly: Boolean,
        judge: Boolean,
    },

    components: {
        PrintCss,
        ImageUpload,
        ImageDialog,
        Draggable,
        AlertDialog,
    },

    data() {
        return {
            panel: 0,
            max: 4,
            model: this.value,
            error: false,
            errorMessage: "",
            rating: null,
            videoSourceShort: this.value.data.videoSourceShort,
            videoSourceFull: this.value.data.videoSourceFull,
            cameras: ["Nikon", "Sony", "Canon", "Other"],
            printers: ["Epson", "Canon", "Outsourced", "Other"],
            papers: ["Canson Infinity", "Epson", "Ilford", "Other"],
            loading: false,
            saving: false,
            contact: null,
            rules: {
                length: (len) => (v) =>
                    (v || "").length <= len || `Answer is too long, maximum character count is ${len}`,
                required: (value) => !!value || "This field is required",
            },
            videos: [{ label: "Short Video" }, { label: "Full Video" }],
            selectedVideo: "Short Video", // default selection
        };
    },

    computed: {
        videoSource() {
            return this.selectedVideo == "Short Video" ? this.videoSourceShort : this.videoSourceFull;
        },
        cameraUsedQuestion() {
            let competition = this.competition;
            console.log(competition?.data?.cameraUsed);
            return competition?.data?.cameraUsed;
        },
        competitionMedia() {
            let competition = this.competition;
            console.log("MEDIA", competition?.data?.media);
            return competition?.data?.media;
        },
        published() {
            return this.entry?.data?.published || false;
        },
        feedback() {
            return this.entry?.posts?.feedback?.posts || [];
        },
        singleFeedback() {
            return this.entry?.posts?.feedback?.posts?.[0] || {};
        },
        entryAuthor() {
            let entry = this.entry;
            return entry?.managedOwners?.[0];
        },
        selectedCategory() {
            if (!this?.model?.data?.category) return;
            return this.categories.find((category) => category._id == this?.model?.data?.category);
        },
        submitted() {
            let entry = this.entry;
            return entry?.data?.submitted;
        },
        isPrint() {
            let competition = this.competition;
            return competition && competition.data && competition.data.type == "print";
        },
        requireDescription() {
            let model = this.model;
            let category = model && model.data && model.data.category;

            // if (this.isPrint) {
            if (category) {
                let catMatch = this.categories.find((cat) => cat._id == category);
                if (catMatch && catMatch._id) {
                    return catMatch && catMatch.data && catMatch.data.requireDescription;
                }
            }
            // }

            return false;
        },
        competition() {
            let model = this.model;
            return (model && model.data && model.data.competition && model.data.competition) || {};
        },
        competitionDates() {
            let competition = this.competition;

            let startDate = competition?.data?.submission?.startDate || "";
            let endDate = competition?.data?.submission?.endDate || "";

            return moment(startDate).format("MMMM Do, YYYY") + " – " + moment(endDate).format("MMMM Do, YYYY");
        },
        competitionImage() {
            let competition = this.competition;

            return competition?.data?.image || false;
        },
        competitionTitle() {
            let competition = this.competition;

            return competition?.title || "";
        },
        valid() {
            let model = this.model;
            let valid = false;

            if (model.title && model.title.length) valid = true;

            return valid;
        },
        image() {
            let entry = this.entry;
            return entry?.data?.image || null;
        },
        questions() {
            let entry = this.entry;
            return entry?.data?.questions || null;
        },
        additional() {
            let entry = this.entry;

            if (!this.readOnly) {
                let additional = entry?.data?.additionalImages || [];
                let empty = [...new Array(8).keys()];

                additional.forEach((image) => empty.pop());

                return additional.concat(empty);
            }

            return entry?.data?.additionalImages || [];
        },
        body() {
            let entry = this.entry;
            return entry?.body || entry?.data?.body;
        },
        category() {
            let entry = this.entry;
            return (
                (entry && entry.data && entry.data.category && entry.data.category.title) ||
                "Category has not been selected"
            );
        },
        cameraUsed() {
            let entry = this.entry;
            let cameraUsed = entry && entry.data && entry.data.cameraUsed;

            if (cameraUsed == "Other") cameraUsed = entry && entry.data && entry.data.cameraOther;

            return cameraUsed;
        },
        printerUsed() {
            let entry = this.entry;
            let printerUsed = entry && entry.data && entry.data.printerUsed;

            if (printerUsed == "Other") printerUsed = entry && entry.data && entry.data.printerOther;

            return printerUsed;
        },
        paperUsed() {
            let entry = this.entry;
            let paperUsed = entry && entry.data && entry.data.paperUsed;

            if (paperUsed == "Other") paperUsed = entry && entry.data && entry.data.paperOther;

            return paperUsed;
        },
        dragOptions() {
            return {
                animation: 250,
                disabled: false,
                ghostClass: "dragging",
            };
        },
        // competitionClosed() {
        //     let currentCompetition = this.competition;
        //     let submission = currentCompetition && currentCompetition.data && currentCompetition.data.submission;

        //     let closed = submission && submission.closed;

        //     let endDate = moment(submission && submission.endDate).format("YYYY-MM-DD");
        //     let currentDate = moment().format("YYYY-MM-DD");

        //     let outOfDateRange = endDate < currentDate;

        //     return closed || outOfDateRange;
        // },
        // competitionHasNotStarted() {
        //     let currentCompetition = this.competition;
        //     let submission = currentCompetition && currentCompetition.data && currentCompetition.data.submission;

        //     let startDate = moment(submission && submission.startDate).format("YYYY-MM-DD");
        //     let currentDate = moment().format("YYYY-MM-DD");

        //     let hasNotStarted = startDate > currentDate;

        //     return hasNotStarted;
        // },
    },

    methods: {
        checkCategory(cat) {
            let competition = this?.model?.data?.competition;
            let categories = this.entries.map(({ data }) => data.category);

            let match = categories.filter((category) => (category?._id ? category._id == cat : null));

            if (match?.length) {
                if (match.length >= this.max && competition?.data?.type == "print") {
                    this.model.data.category = null;
                    this.$refs.alertDialog.open(
                        null,
                        `You can only have up to ${this.max} entries in the same category for this competition`
                    );
                }
            }
        },
        enlarge(image) {
            this.$refs.imageDialog.open(image);
        },
        close() {
            this.$router.push({ name: "online-judging" });
        },
        async confirm(data) {
            console.log("save");
            await this.$fluro.resetCache();
            this.$refs.judgeDialog.close();
        },

        convertToEmbedUrl(url) {
            const regex = /^https:\/\/www\.youtube\.com\/watch\?v=(.*)$/;
            const match = url.match(regex);
            if (match) {
                return `https://www.youtube.com/embed/${match[1]}`;
            }
            // If URL is not in expected format, return it as is
            return url;
        },
        async init() {
            try {
                let id = this?.entryAuthor?._id || "";

                let payload = {
                    _type: "contact",
                    "data.personaId": id,
                };

                let contact = await this.$fluro.api.post("/content/_query", payload).then(({ data }) => data);

                this.contact = (contact && contact[0]) || null;
            } catch (err) {
                console.log("ERROR", err);
            }
        },
    },
    watch: {
        videoSourceShort(newVal) {
            const newURL = this.convertToEmbedUrl(newVal);
            this.model.data.videoSourceShort = newURL;
            console.log(newURL);
        },
        videoSourceFull(newVal) {
            const newURL = this.convertToEmbedUrl(newVal);
            this.model.data.videoSourceFull = newURL;
            console.log(newURL);
        },
    },
    async mounted() {
        await this.init();
    },
};
</script>

<style scoped>
.select-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-select {
    margin-left: 35%;
    margin-right: 35%;
}
</style>
