<template>
    <v-dialog v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly" :transition="false" max-width="90vw" max-height="90vh">
        <!--:transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'"-->
        <v-card flat class="image-dialog">
            <div class="dialog-header zindex-10">
                <div class="header-text">
                    <div class="icon-outer">
                        <v-icon>mdi-image-outline</v-icon>
                    </div>

                    <div class="text">
                        <h3>View Image</h3>
                    </div>
                </div>

                <div class="actions">
                    <div class="filter-buttons">
                        <template v-if="$vuetify.breakpoint.smAndUp">
                            <v-btn small :class="{ 'light--text': panning }" color="dark" :text="!panning" :disabled="loading" @click="pan()">
                                <v-icon small class="mr-2">mdi-magnify-expand</v-icon>
                                Pan
                            </v-btn>

                            <v-btn small class="dark--text" color="grey" :disabled="loading" @click="close()"> Close </v-btn>
                        </template>

                        <template v-else>
                            <v-btn small icon color="dark" :text="!panning" :disabled="loading" @click="pan()">
                                <v-icon small>mdi-magnify-expand</v-icon>
                            </v-btn>

                            <v-btn small icon class="dark--text" color="grey" :disabled="loading" @click="close()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>

                        <slot name="actions"></slot>
                    </div>
                </div>
            </div>

            <div class="dialog-error error px-4" :class="{ active: errorMessage && errorMessage.length }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="errorMessage = ''">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="dialog-body">
                <div class="asset-outer">
                    <template v-if="!panning">
                        <div class="p-absolute fit d-flex align-center justify-center pa-6">
                            <v-img v-if="model" contain class="full-width full-height" :src="$fluro.asset.imageUrl(model)">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height grey ma-0" align="center" justify="center">
                                        <v-progress-circular :width="2" :size="20" color="dark" indeterminate></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </div>
                    </template>

                    <template v-else>
                        <img class="d-block" v-if="model" :src="$fluro.asset.imageUrl(model, 3000, 5000, { quality: 100 })" />
                    </template>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
    name: "image-dialog",

    props: {
        item: Object,
    },

    data() {
        return {
            panning: false,
            loading: false,
            showError: false,
            model: null,
            dialog: false,
            errorMessage: "",
        };
    },

    methods: {
        init(model) {
            this.showError = false;
            this.loading = false;

            if (!model) {
                this.model = null;
            } else {
                let clonedModel = _.cloneDeep(model);
                this.model = clonedModel;
            }
        },
        open(model) {
            this.init(model);
            this.dialog = true;
        },
        pan() {
            this.panning = !this.panning;
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        close() {
            this.dialog = false;
            this.panning = false;
        },
    },
};
</script>

<style lang="scss">
.image-dialog {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 90vh !important;

    .dialog-footer {
        padding: 5px 15px;
        background: var(--v-light-base);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
    }

    .dialog-error {
        height: 0;
        overflow: hidden;
        transition: height 0.2s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &.active {
            height: 60px;
        }
    }

    .dialog-header {
        padding: 15px;
        background: var(--v-light-base);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;

        .icon-outer {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 15px;
            flex-shrink: 0;

            &:before {
                content: "";
                background: var(--v-primary-base);
                width: 100%;
                height: 100%;
                opacity: 0.1;
                position: absolute;
                top: 0;
                left: 0;
            }

            .v-icon {
                color: var(--v-primary-base);
            }
        }

        .header-text {
            display: flex;
            align-items: center;
        }

        .text {
            .normal {
                font-weight: normal;
                color: #dddddd;
            }
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .v-list-item {
                min-height: 40px;
                border-radius: 4px !important;
                flex: initial;

                &:before {
                    border-radius: 4px !important;
                }
            }

            .filter-buttons {
                display: flex;
                align-items: center;
                margin: 0 0 0 -12px;

                .v-btn {
                    margin-left: 16px;
                }
            }
        }
    }

    .dialog-body {
        height: 100%;
        overflow-y: scroll;
        // padding: 30px;
        background: var(--v-background-base);
        position: relative;
    }
}
</style>
