import FluroContent from "./fluro-content";

class Category extends FluroContent {
    constructor() {
        super("category");
    }
}

const _Category = new Category();
export default _Category;
