<template>
    <div class="artist-submission d-flex flex-column full-height full-width" scrollable>
        <div class="dialog-error error px-4" :class="{ active: error }">
            <div class="text d-flex align-center">
                <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                <b class="white--text">{{ errorMessage }}</b>
            </div>

            <v-btn x-small icon fab color="white" @click="error = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>

        <div class="overflow-hidden full-height full-width p-relative">
            <template v-if="loading">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <div class="p-absolute top zindex-10 full-width">
                    <v-container
                        fluid
                        class="pa-6 page-header"
                        :class="{ scrolled: scrolled || $vuetify.breakpoint.xsOnly }"
                        v-if="entry && entry._id"
                    >
                        <v-row>
                            <v-col class="d-flex align-center justify-space-between" cols="12">
                                <h2>Submission</h2>

                                <div class="action-buttons">
                                    <v-btn
                                        class="ml-3"
                                        :class="{ grey: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="grey"
                                        small
                                        @click="close"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="dark" small>mdi-close</v-icon>
                                        </template>

                                        <template v-else>Close</template>
                                    </v-btn>

                                    <template v-if="entry.isCategoryJudge">
                                        <template v-if="abstain && abstain._id">
                                            <v-btn
                                                class="ml-3"
                                                :class="{ grey: $vuetify.breakpoint.xsOnly }"
                                                :icon="$vuetify.breakpoint.xsOnly"
                                                disabled
                                                small
                                            >
                                                <template v-if="$vuetify.breakpoint.xsOnly">
                                                    <v-icon color="dark" small>mdi-account-cancel-outline</v-icon>
                                                </template>

                                                <template v-else>Abstained</template>
                                            </v-btn>
                                        </template>

                                        <template v-else>
                                            <template v-if="score && score._id">
                                                <template v-if="!submitted">
                                                    <v-btn
                                                        class="ml-3"
                                                        :class="{ primary: $vuetify.breakpoint.xsOnly }"
                                                        :icon="$vuetify.breakpoint.xsOnly"
                                                        color="primary"
                                                        small
                                                        :loading="saving"
                                                        @click="$refs.judgeDialog.open(entry, score)"
                                                    >
                                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                                            <span class="white--text">{{ score.data.score }}</span>
                                                        </template>

                                                        <template v-else>
                                                            <span class="score">
                                                                <span>{{ score.data.score }}</span>
                                                            </span>
                                                            <span class="ml-2">Edit Score</span>
                                                        </template>
                                                    </v-btn>

                                                    <v-btn
                                                        class="ml-3 light--text"
                                                        :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                                        :icon="$vuetify.breakpoint.xsOnly"
                                                        color="dark"
                                                        small
                                                        :loading="finishing"
                                                        @click="finishJudging()"
                                                    >
                                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                                            <v-icon color="white" small>mdi-tray-arrow-up</v-icon>
                                                        </template>

                                                        <template v-else>Finish Judging</template>
                                                    </v-btn>
                                                </template>
                                            </template>

                                            <template v-else>
                                                <v-btn
                                                    class="ml-3"
                                                    :class="{ success: $vuetify.breakpoint.xsOnly }"
                                                    :icon="$vuetify.breakpoint.xsOnly"
                                                    color="success"
                                                    small
                                                    :loading="saving"
                                                    @click="$refs.judgeDialog.open(entry)"
                                                >
                                                    <template v-if="$vuetify.breakpoint.xsOnly">
                                                        <v-icon color="white" small>mdi-account-check-outline</v-icon>
                                                    </template>

                                                    <template v-else>Judge Submission</template>
                                                </v-btn>
                                            </template>

                                            <v-btn
                                                class="ml-3"
                                                :class="{ error: $vuetify.breakpoint.xsOnly }"
                                                :icon="$vuetify.breakpoint.xsOnly"
                                                color="error"
                                                small
                                                @click="$refs.abstainDialog.open(entry)"
                                                v-if="!score || !score._id"
                                            >
                                                <template v-if="$vuetify.breakpoint.xsOnly">
                                                    <v-icon color="white" small>mdi-account-cancel-outline</v-icon>
                                                </template>

                                                <template v-else>Abstain from Judging</template>
                                            </v-btn>
                                            <v-btn
                                                class="ml-3"
                                                :class="{ error: $vuetify.breakpoint.xsOnly }"
                                                :icon="$vuetify.breakpoint.xsOnly"
                                                color="red"
                                                small
                                                @click="$refs.tagDialog.open(entry)"
                                            >
                                                <template v-if="$vuetify.breakpoint.xsOnly">
                                                    <v-icon color="white" small>mdi-tag-add</v-icon>
                                                </template>

                                                <template v-else>Add Tag</template>
                                            </v-btn>
                                        </template>
                                    </template>

                                    <template v-if="entry.isFeedbackJudge && entry.data && entry.data.feedback">
                                        <template v-if="feedback && feedback._id">
                                            <!-- <template v-if="!submitted"> -->
                                            <v-btn
                                                class="ml-3"
                                                :class="{ primary: $vuetify.breakpoint.xsOnly }"
                                                :icon="$vuetify.breakpoint.xsOnly"
                                                color="primary"
                                                small
                                                :loading="saving"
                                                @click="$refs.feedbackDialog.open(entry, feedback)"
                                            >
                                                <span>Edit Feedback</span>
                                            </v-btn>

                                            <!-- <v-btn class="ml-3 light--text" :class="{ dark: $vuetify.breakpoint.xsOnly }" :icon="$vuetify.breakpoint.xsOnly" color="dark" small :loading="finishing" @click="finishJudging()">
                                                    <template v-if="$vuetify.breakpoint.xsOnly">
                                                        <v-icon color="white" small>mdi-tray-arrow-up</v-icon>
                                                    </template>

                                                    <template v-else>Finish Judging</template>
                                                </v-btn> -->
                                            <!-- </template> -->
                                        </template>

                                        <template v-else-if="!entry.maxFeedback">
                                            <v-btn
                                                class="ml-3"
                                                :class="{ success: $vuetify.breakpoint.xsOnly }"
                                                :icon="$vuetify.breakpoint.xsOnly"
                                                color="primary"
                                                small
                                                :loading="saving"
                                                @click="$refs.feedbackDialog.open(entry)"
                                            >
                                                <template v-if="$vuetify.breakpoint.xsOnly">
                                                    <v-icon color="white" small>mdi-account-check-outline</v-icon>
                                                </template>

                                                <template v-else>Give Feedback</template>
                                            </v-btn>
                                        </template>
                                    </template>

                                    <template v-if="hasNext">
                                        <v-btn
                                            class="ml-3"
                                            :class="{ success: $vuetify.breakpoint.xsOnly }"
                                            :icon="$vuetify.breakpoint.xsOnly"
                                            color="success"
                                            small
                                            @click="next()"
                                        >
                                            <template v-if="$vuetify.breakpoint.xsOnly">
                                                <v-icon color="white" small>mdi-arrow-right</v-icon>
                                            </template>

                                            <template v-else>
                                                <span>Next</span>
                                                <v-icon class="ml-2" color="white" small>mdi-arrow-right</v-icon>
                                            </template>
                                        </v-btn>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div class="overflow-y-scroll full-height full-width">
                    <div class="spacer"></div>

                    <v-container style="max-width: 768px" v-if="abstain">
                        <h3 class="mb-6">Reason for Abstaining</h3>
                        <div v-html="abstain.data.reason"></div>
                    </v-container>
                    <!-- TO DO ADD TAG BUTTON! -->
                    <v-container style="max-width: 768px" v-if="submitted">
                        <h3 class="mb-6">
                            Your Final Score:&nbsp;<span class="primary--text">{{ score.data.score }}</span>
                        </h3>
                    </v-container>

                    <v-container style="max-width: 768px" v-if="feedback">
                        <h3 class="mb-6">Your feedback</h3>
                        <div v-html="feedback.data.feedback"></div>
                    </v-container>

                    <submission v-model="entry" :entry="entry" :categories="categories" read-only judge></submission>
                    <v-col cols="12" v-if="questions">
                        <h3 class="mb-6">Questions</h3>

                        <div class="group-item pa-6">
                            <v-row>
                                <v-col cols="12" v-for="(question, index) in questions" :key="index">
                                    <template v-if="question.category.some((p) => p == entry.data.category._id)">
                                        <p class="primary--text small--text">{{ question.question }}</p>
                                        <p v-if="entry.data.questions[index]" disabled rows="1" auto-grow>
                                            {{ entry.data.questions[index] }}
                                        </p>
                                        <p v-else disabled rows="1" auto-grow>No Answer Provided</p>
                                    </template>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </div>
            </template>
        </div>

        <v-snackbar v-model="snackbar" timeout="3000" :color="snackbarColor" top>
            <span class="full-width text-center d-flex align-center">
                <v-icon class="mr-2">{{ snackbarIcon }}</v-icon>
                {{ snackbarText }}</span
            >
        </v-snackbar>

        <judge-dialog ref="judgeDialog" @confirm="confirm"></judge-dialog>
        <feedback-dialog ref="feedbackDialog" @confirm="confirm"></feedback-dialog>
        <abstain-dialog ref="abstainDialog" @confirm="confirm"></abstain-dialog>
        <alert-dialog ref="finishDialog" title="Submit Score" @confirm="confirmFinish"></alert-dialog>
        <content-list-dialog
            v-model="tagSelect"
            ref="tagDialog"
            title="Select Tags"
            type="tag"
            @confirm="confirmTags"
        ></content-list-dialog>
        <process-dialog ref="processDialog" :title="processTitle"></process-dialog>
    </div>
</template>

<script>
// Services
import Entry from "@/modules/app-module/entry";
import Category from "@/modules/app-module/category";
import Score from "@/modules/app-module/score";

// Components

import JudgeDialog from "@/components/dialogs/judge-dialog.vue";
import FeedbackDialog from "@/components/dialogs/feedback-dialog.vue";
import AbstainDialog from "@/components/dialogs/abstain-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";
import Submission from "@/components/layout/submission.vue";
import ContentListDialog from "../../../components/dialogs/content-list-dialog.vue";
import ProcessDialog from "@/components/dialogs/process-dialog.vue";
// Packages
import _ from "lodash";
import moment from "moment";

export default {
    name: "artist-submission",

    meta: {
        title: "Submission",
    },

    components: {
        AlertDialog,
        AbstainDialog,
        JudgeDialog,
        Submission,
        FeedbackDialog,
        ContentListDialog,
        ProcessDialog,
    },

    data() {
        return {
            tagSelect: [],
            error: false,
            scrolled: false,
            errorMessage: "",
            entry: {},
            nextEntry: {},
            score: null,
            abstain: null,
            feedback: null,
            categories: [],
            loading: false,
            saving: false,
            finishing: false,
            snackbar: false,
            snackbarText: "",
            snackbarIcon: "",
            snackbarColor: "",
        };
    },

    computed: {
        tags() {
            return this.entry?.tags;
        },
        questions() {
            return this.entry.data.competition.data.questions;
        },
        submitted() {
            return this.score?.data?.submitted;
        },
        hasNext() {
            let score = this.score;
            let abstain = this.abstain;
            let feedback = this.feedback;

            if (this?.nextEntry?._id) {
                if (score?._id || abstain?._id || feedback?._id) return true;
            }

            return false;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            await this.$fluro.resetCache();

            let user = this.$app.user;

            try {
                // const entryQuery = {
                //     status: "active",
                //     definition: "entry",
                //     realms: {
                //         $in: this.$app.liveRealm,
                //     },
                // };

                // const categoryQuery = {
                //     status: "active",
                //     definition: "category",
                //     realms: {
                //         $in: this.$app.liveRealm,
                //     },
                // };

                // const [entryResponse, categoryResponse] = await Promise.all([
                //     Entry.query(entryQuery, { params: { status: "active", appendPosts: "all" } }),
                //     Category.query(categoryQuery),
                // ]);

                const categoryQuery = {
                    status: "active",
                    definition: "category",
                    realms: {
                        $in: this.$app.liveRealm,
                    },
                };

                const categoryResponse = await Category.query(categoryQuery);
                this.categories = categoryResponse.data;

                const entryQueries = [];

                // Generate queries for the previous 12 months
                for (let i = 0; i < 12; i++) {
                    const startDate = new Date();
                    startDate.setUTCMonth(startDate.getUTCMonth() - i, 1);
                    startDate.setUTCHours(0, 0, 0, 0);

                    const endDate = new Date(startDate);
                    endDate.setUTCMonth(endDate.getUTCMonth() + 1);

                    const entryQuery = {
                        status: "active",
                        definition: "entry",
                        realms: {
                            $in: this.$app.liveRealm,
                        },
                        created: {
                            $gte: startDate,
                            $lt: endDate,
                        },
                    };

                    entryQueries.push(entryQuery);
                }

                const entryPromises = entryQueries.map((query) =>
                    Entry.query(query, { params: { status: "active", appendPosts: "all" } })
                );
                const entryResponses = await Promise.all(entryPromises);
                let entries = entryResponses.flatMap((response) => response.data);

                // Filter the judges categories
                entries = entries
                    .map((entry) => {
                        let competition = entry?.data?.competition;
                        let category = entry?.data?.category;

                        let categoryJudges = competition?.data?.categoryJudges || [];
                        let feedbackJudges = competition?.data?.feedbackJudges || [];

                        let isCategoryJudge = categoryJudges?.some(
                            (item) =>
                                (item?.category?._id || item?.category) == category?._id &&
                                item?.judges?.some((judge) => (judge?._id || judge) == user.persona)
                        );
                        entry.isCategoryJudge = isCategoryJudge ? true : false;

                        let isFeedbackJudge = feedbackJudges?.some(
                            (item) =>
                                (item?.category?._id || item?.category) == category?._id &&
                                item?.judges?.some((judge) => (judge?._id || judge) == user.persona)
                        );
                        entry.isFeedbackJudge = isFeedbackJudge ? true : false;

                        //Lock image due to limits
                        entry.maxFeedback =
                            entry?.data?.competition?.data?.maxFeedbackCount >= entry?.posts?.feedback?.count
                                ? true
                                : false;
                        entry.maxScoreCount = entry?.data?.competition?.data?.maxScoreCount;
                        entry.maxScore =
                            entry?.data?.competition?.data?.maxScoreCount >= entry?.posts?.score?.count ? true : false;
                        return entry;
                    })
                    .filter((entry) => {
                        let { isCategoryJudge, isFeedbackJudge } = entry;

                        // Return this entry if the user is a judge or they are a feedback judge and the entry requires feedback
                        return isCategoryJudge || (isFeedbackJudge && entry?.data?.feedback);
                    });

                //////////////////////////////////////////////////

                this.entry = entries.find((entry) => entry._id == this.$route.params.id);

                let nextPossibleEntries = entries
                    .filter((entry) => {
                        let compMatch = entry?.data?.competition?._id == this?.entry?.data?.competition?._id;

                        let catMatch = entry?.data?.category?._id == this.entry?.data?.category?._id;

                        let current = entry?._id === this.entry?._id;

                        let score = entry?.posts?.score?.posts?.find((post) => post?.managedAuthor == user.persona);

                        let submitted = entry?.posts?.score?.posts?.find(
                            (post) => post?.managedAuthor == user.persona && post?.data?.submitted
                        );

                        let abstain = entry?.posts?.abstain?.posts?.find((post) => post?.managedAuthor == user.persona);

                        let feedback = entry?.posts?.feedback?.posts?.find(
                            (post) => post?.managedAuthor == user.persona
                        );

                        let { isCategoryJudge, isFeedbackJudge } = entry;

                        let needsJudging =
                            compMatch && catMatch && !current && (!score || (score && !submitted)) && !abstain;
                        let needsFeedback = compMatch && catMatch && !current && !feedback && entry?.data?.feedback;

                        if (abstain) {
                            needsFeedback = false;
                            needsJudging = false;
                        }

                        // Return if
                        // The user is both a category and feedback judge and the entry needs either a score or feedback
                        // Ths user is a category judge but not feedback and the entry needs a score
                        // The user is not a category judge but is a feedbacka and the entry needs feedback

                        return isCategoryJudge && isFeedbackJudge
                            ? needsJudging || needsFeedback
                            : isCategoryJudge && !isFeedbackJudge
                            ? needsJudging
                            : !isCategoryJudge && isFeedbackJudge
                            ? needsFeedback
                            : false;
                    })
                    .sort((a, b) => {
                        let aScore = a?.posts?.score?.posts?.find((post) => post?.managedAuthor == user.persona);
                        let bScore = b?.posts?.score?.posts?.find((post) => post?.managedAuthor == user.persona);

                        return aScore === bScore ? 0 : aScore ? 1 : -1;
                    });

                this.nextEntry = nextPossibleEntries?.[0] || {};

                console.log("NEXT ENTRY", this.nextEntry);

                //////////////////////////////////////////////////

                // this.categories = await Category.list().then(({ data }) => data);

                let posts = this.entry?.posts;

                if (posts) {
                    let score = posts?.score;
                    let abstain = posts?.abstain;
                    let feedback = posts?.feedback;

                    if (score) {
                        this.score = score.posts.find((post) => post.managedAuthor == user.persona);
                        console.log("SCORE", this.score);
                    }

                    if (abstain) {
                        this.abstain = abstain.posts.find((post) => post.managedAuthor == user.persona);
                        console.log("ABSTAIN", this.abstain);
                    }

                    if (feedback) {
                        this.feedback = feedback.posts.find((post) => post.managedAuthor == user.persona);
                        console.log("FEEDBACK", this.feedback);
                    }
                }

                if (!this.nextEntry?._id) {
                    let { isCategoryJudge, isFeedbackJudge } = this.entry;

                    let score = this.score;
                    let submitted = score?.data?.submitted;
                    let abstain = this.abstain;
                    let feedback = this.feedback;

                    let needsJudging = (!score || (score && !submitted)) && !abstain;
                    let needsFeedback = !feedback && this?.entry?.data?.feedback;

                    let incomplete =
                        isCategoryJudge && isFeedbackJudge
                            ? needsJudging || needsFeedback
                            : isCategoryJudge && !isFeedbackJudge
                            ? needsJudging
                            : !isCategoryJudge && isFeedbackJudge
                            ? needsFeedback
                            : false;

                    if (!incomplete) {
                        this.snackbar = true;
                        this.snackbarText = "You have completed this category!";
                        this.snackbarIcon = "mdi-check-circle";
                        this.snackbarColor = "success";
                    }
                }

                console.log("ENTRY", this.entry);
                console.log("CATEGORIES", this.categories);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
        close() {
            this.$router.push({ name: "online-judging" });
        },
        async confirm(data) {
            await this.$fluro.resetCache();
            await this.init();

            this.$refs.judgeDialog.close();
            this.$refs.abstainDialog.close();
            this.$refs.finishDialog.close();
            this.$refs.feedbackDialog.close();
        },
        finishJudging() {
            this.$refs.finishDialog.open(
                null,
                `Are you sure you want to submit your score? You will not be able to modify your score anymore.`
            );
        },
        async confirmFinish() {
            this.finishing = true;

            try {
                let payload = {
                    data: {
                        submitted: true,
                    },
                };

                let updated = await Score.update(this.score._id, payload).then(({ data }) => data);
                console.log("UPDATED", updated);

                this.confirm();
            } catch (err) {
                console.log("ERROR");
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.finishing = false;
        },
        next() {
            this.$router.push({ name: this.$route.name, params: { id: this.nextEntry._id } });
        },
        refresh() {
            this.loading = true;

            this.error = false;
            this.scrolled = false;
            this.errorMessage = "";
            this.entry = {};
            this.nextEntry = {};
            this.score = null;
            this.abstain = null;
            this.feedback = null;
            this.categories = [];
            this.saving = false;
            this.finishing = false;
            this.snackbar = false;
            this.snackbarText = "";
            this.snackbarIcon = "";
            this.snackbarColor = "";

            this.init();
        },
        handleScroll(e) {
            let scrollTop = e.target.scrollTop;

            if (scrollTop > 30) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
        async confirmTags(tags, index, key) {
            this.$refs.tagDialog.close();

            this.$refs.processDialog.open("1");

            let loop = async () => {
                try {
                    const item = this.entry;

                    let payload = {};

                    let mappedItemTags = item?.tags?.map(({ _id }) => _id) || [];
                    let mappedSelectedTags = tags?.map(({ _id }) => _id) || [];
                    console.log(mappedSelectedTags);
                    if (!mappedItemTags || !mappedItemTags?.length) {
                        payload.tags = tags.map(({ _id }) => _id);
                    } else {
                        let array = mappedItemTags.concat(mappedSelectedTags);
                        payload.tags = array.filter((v, i, a) => a.indexOf(v) === i);
                    }
                    //Add persona attached tags
                    if (item.data.judgeAddedTags) {
                        payload.data = {
                            judgeAddedTags: [
                                ...item.data.judgeAddedTags,
                                {
                                    persona: this.$app.user.persona,
                                    tags: mappedSelectedTags,
                                },
                            ],
                        };
                    } else {
                        payload.data = {
                            judgeAddedTags: [
                                {
                                    persona: this.$app.user.persona,
                                    tags: mappedSelectedTags,
                                },
                            ],
                        };
                    }

                    let updated = await Entry.update(item._id, payload).then(({ data }) => data);
                    console.log("UPDATED", updated);

                    this.$refs.processDialog.increment();
                } catch (err) {
                    console.log("PROCESS ERROR", err);
                }
            };

            await loop();

            // Buffer to let 100% sit for a second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            this.$refs.processDialog.close();
        },
    },

    watch: {
        "$route.params.id": function (v) {
            if (v) this.refresh();
        },
    },

    async mounted() {
        await this.init();

        this.$nextTick(() => {
            const scrollContainer = document.querySelector(".overflow-y-scroll");
            scrollContainer.addEventListener("scroll", this.handleScroll);
        });
    },

    destroyed() {
        const scrollContainer = document.querySelector(".overflow-y-scroll");
        if (scrollContainer) scrollContainer.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style lang="scss">
.artist-submission {
    .score {
        background: var(--v-light-base);
        padding: 0 5px;
        height: 20px;
        border-radius: 40px;
        color: var(--v-primary-base);
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 8px;
    }
}
</style>
