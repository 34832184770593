import FluroContent from "./fluro-content";

class Score extends FluroContent {
    constructor() {
        super("score");
    }
}

const _Score = new Score();
export default _Score;
