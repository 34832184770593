<template>
    <v-card
        class="entry elevation-0"
        :class="{
            archived: archived,
            empty: isEmpty,
            disabled: create ? false : isEmpty && !$status.competitionOpen(competition),
        }"
        @click="clicked()"
    >
        <div class="entry-inner">
            <template v-if="!isEmpty">
                <template v-if="entry.maxScore && !entry.score">
                    <span class="entry-tag grey">Closed</span>
                </template>
                <div class="image-container flex-shrink-1">
                    <template v-if="hasImage">
                        <v-img
                            class="tile-image rounded-lg grey"
                            :class="{ background: isPicker }"
                            :src="imageUrl"
                            :contain="isPicker"
                        >
                            <template v-slot:placeholder>
                                <v-row class="fill-height grey ma-0" align="center" justify="center">
                                    <v-progress-circular
                                        :width="2"
                                        :size="20"
                                        color="dark"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>

                        <template v-if="!isJudge">
                            <div class="p-absolute top left" v-if="submitted && isPrint">
                                <v-btn class="pa-2" color="light" icon @click.stop="print()">
                                    <v-icon color="white" small>mdi-printer-outline</v-icon>
                                </v-btn>
                            </div>

                            <div class="p-absolute top right5" v-if="archived">
                                <div class="status-cell">
                                    <span class="inline-tag archived white--text">archived</span>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="hasVideoShort">
                        <v-img
                            class="tile-image rounded-lg grey"
                            :class="{ background: isPicker }"
                            :src="getThumbnailFromEmbedUrl(hasVideoShort)"
                            :contain="isPicker"
                        >
                            <template v-slot:placeholder>
                                <v-row class="fill-height grey ma-0" align="center" justify="center">
                                    <v-progress-circular
                                        :width="2"
                                        :size="20"
                                        color="dark"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>

                        <template v-if="!isJudge">
                            <!-- <div class="p-absolute top left" v-if="submitted && isPrint">
                                <v-btn class="pa-2" color="light" icon @click.stop="print()">
                                    <v-icon color="white" small>mdi-printer-outline</v-icon>
                                </v-btn>
                            </div> -->

                            <div class="p-absolute top right5" v-if="archived">
                                <div class="status-cell">
                                    <span class="inline-tag archived white--text">archived</span>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <v-row class="rounded-lg fill-height full-width grey ma-0" align="center" justify="center">
                            <v-icon color="dark-grey" x-large>mdi-image-outline</v-icon>
                        </v-row>
                    </template>

                    <template v-if="published && !isJudge">
                        <div class="final-score">
                            <div class="score-outer">
                                <h1 class="white--text zindex-10" v-html="score"></h1>
                            </div>
                        </div>
                    </template>
                </div>

                <template v-if="!isPicker">
                    <div class="light pa-3 full-width d-flex flex-column align-start flex-grow-1">
                        <template v-if="!isJudge">
                            <span class="small--text text-overflow" v-if="entryCode"
                                ><b class="mb-1">Entry ID:</b> {{ entryCode }}</span
                            >
                            <span class="small--text text-overflow" v-if="entryCategory"
                                ><b class="mb-1">Category:</b> {{ entryCategory.title }}</span
                            >

                            <template v-if="published && awards.length">
                                <div class="d-flex align-center">
                                    <div class="mr-1 rounded-lg" v-for="(item, index) in awards" :key="index">
                                        <v-img
                                            class="rounded-lg"
                                            :src="$fluro.asset.imageUrl(item)"
                                            width="25"
                                            height="25"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row class="fill-height ma-0" align="center" justify="center">
                                                    <v-progress-circular
                                                        :width="1"
                                                        :size="14"
                                                        color="dark"
                                                        indeterminate
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </div>
                                </div>
                            </template>

                            <template v-else-if="submitted">
                                <div class="text-overflow success--text">
                                    <span class="entry-tag success">Submitted: {{ entry | date }}</span>
                                </div>
                            </template>

                            <template v-else-if="!$status.competitionOpen(competition) && $status.hasEnoughInfo(entry)">
                                <div class="text-overflow success--text">
                                    <span class="entry-tag success">Submitted</span>
                                </div>
                            </template>

                            <template v-else-if="$status.hasEnoughInfo(entry)">
                                <div class="text-overflow warning--text">
                                    <span class="entry-tag warning">Ready for Submission</span>
                                </div>
                            </template>

                            <template v-else>
                                <div class="text-overflow error--text">
                                    <span class="entry-tag error">Not Submitted</span>
                                </div>
                            </template>
                        </template>

                        <template v-else>
                            <template v-if="entry.isCategoryJudge">
                                <template v-if="entry.score && entry.score.data && entry.score.data.submitted">
                                    <span class="entry-tag mr-2">Submitted: {{ entry.score.data.score }}</span>
                                </template>

                                <template v-else-if="entry.score">
                                    <span class="entry-tag pending mr-2"
                                        >Score: {{ entry.score.data && entry.score.data.score }}</span
                                    >
                                </template>

                                <template v-else-if="entry.abstain">
                                    <span class="entry-tag grey mr-2">Abstained</span>
                                </template>
                                <template v-else-if="entry.maxScore">
                                    <span class="entry-tag grey mr-2">Score Limit Reached</span>
                                </template>
                                <template v-else>
                                    <span class="entry-tag error mr-2">No Score</span>
                                </template>
                            </template>

                            <template v-if="entry.isFeedbackJudge && entry.data && entry.data.feedback">
                                <template v-if="entry.feedback">
                                    <span class="entry-tag success">Feedback Submitted</span>
                                </template>
                                <template v-else-if="entry.maxFeedback">
                                    <span class="entry-tag grey">Feedback Limit Reached</span>
                                </template>
                                <template v-else>
                                    <span class="entry-tag error">Requires feedback</span>
                                </template>
                            </template>
                        </template>
                    </div>
                </template>
            </template>

            <template v-else>
                <b class="dark-grey--text">Add Entry</b>
                <v-icon color="dark-grey">mdi-plus</v-icon>
            </template>
        </div>
    </v-card>
</template>

<script>
import moment from "moment";

export default {
    name: "entry-tile",

    props: {
        create: {
            type: Boolean,
            default() {
                return false;
            },
        },
        entry: {
            type: Object,
            default() {
                return {};
            },
        },
        competition: {
            type: Object,
            default() {
                return {};
            },
        },
        view: {
            type: String,
            default: "artist",
        },
    },

    filters: {
        date(value) {
            if (!value) return "--/--/----";

            return value?.data?.submittedOn && moment(value.data.submittedOn).format("Do MMMM, YYYY");
        },
    },

    components: {},

    data() {
        return {};
    },

    computed: {
        isEmpty() {
            return this.view == "empty";
        },
        isJudge() {
            return this.view == "judge";
        },
        isPicker() {
            return this.view == "picker";
        },
        archived() {
            return this.entry?.status == "archived";
        },
        entryCode() {
            return this.entry?.data?.entryCode || null;
        },
        entryCategory() {
            return this.entry?.data?.category || null;
        },
        score() {
            return this.entry?.data?.score || 0;
        },
        awards() {
            let tags = this.entry?.tags || [];
            let compAwards = this.competition?.data?.awards || [];
            let awards = [];

            if (!tags || !compAwards) return awards;

            awards = tags
                .map((tag) => {
                    let award = compAwards.find((award) => (award.tag?._id || award.tag) == tag._id);
                    return award?.image;
                })
                .filter((award) => award);

            return awards;
        },
        published() {
            return this.entry?.data?.published || false;
        },
        isPrint() {
            let competition = this.entry?.data?.competition;
            return competition?.data?.type == "print";
        },
        submitted() {
            return this.entry?.data?.submitted;
        },
        hasImage() {
            let image = this.entry?.data?.image;

            return image?._id || image;
        },
        hasVideoShort() {
            let video = this.entry?.data?.videoSourceShort;

            return video;
        },

        imageUrl() {
            return this.$fluro.asset.imageUrl(this.entry?.data?.image) || "";
        },
    },

    methods: {
        getThumbnailFromEmbedUrl(embedUrl) {
            const regex = /^https:\/\/www\.youtube\.com\/embed\/(.*)$/;
            const match = embedUrl.match(regex);
            if (match) {
                return `https://img.youtube.com/vi/${match[1]}/hqdefault.jpg`;
            }
            // If URL is not in expected format, return null
            return null;
        },
        clicked() {
            if (this.entry.maxScore && !this.entry.score) return;

            //Add user feeback here to show its closed visually
            this.$emit("click", this.entry);
        },
        print() {
            this.$emit("print", this.entry);
        },
    },

    async mounted() {},
};
</script>

<style lang="scss">
.entry {
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 7px;
    overflow: hidden;

    &.archived {
        // opacity: 0.7;
    }

    &.empty {
        border: 2px dashed var(--v-dark-grey-base);
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;
    }

    .entry-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .image-container {
            // padding-bottom: 100%;
            height: 100%;
            position: relative;
            width: 100%;
            overflow: hidden;

            .tile-image,
            .row {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .final-score {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 7px;
                overflow: hidden;
                pointer-events: none;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--v-dark-base);
                    opacity: 0.3;
                    border-radius: 7px;
                    overflow: hidden;
                }

                .score-outer {
                    border-radius: 7px;
                    padding: 2px 6px;
                    background: var(--v-success-base);
                    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
                    z-index: 10;

                    h1 {
                        line-height: 1;
                    }
                }
            }
        }
    }

    .entry-tag {
        font-size: 10px;
        padding: 3px 5px;
        border-radius: 4px;
        position: relative;
        background-color: transparent !important;
        background: transparent !important;

        &:before {
            content: "";
            opacity: 0.1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.success {
            &:before {
                background-color: var(--v-success-base);
            }

            color: var(--v-dark-base);
        }

        &.error {
            &:before {
                background-color: var(--v-error-base);
            }

            color: var(--v-dark-base);
        }

        &.warning {
            &:before {
                background-color: var(--v-warning-base);
            }

            color: var(--v-dark-base);
        }

        &.pending {
            color: var(--v-dark-base);

            &:before {
                background-color: var(--v-info-base);
            }
        }

        &.grey {
            background-color: var(--v-grey-base) !important;

            color: var(--v-dark-base);
        }
    }
}
</style>
