import FluroContent from "./fluro-content";

class Abstain extends FluroContent {
    constructor() {
        super("abstain");
    }
}

const _Abstain = new Abstain();
export default _Abstain;
